
import { fireService } from "@/service";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "MyEvents",
  setup() {
    const colors = {
      "1": "linear-gradient(270deg, #FFC65D 0%, #FFA500 98%)",
      "2": "linear-gradient(270deg, #3484FF 0%, #0065FF 98%)",
      "3": "linear-gradient(270deg, #FF8264 0%, #FF5B34 100%)",
      "4": "linear-gradient(270deg, #C6C9D1 0%, #9699A2 100%)"
    };
    const state = reactive({
      list: [] as any[],
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      pageSize: 20
    });

    const onLoad = async () => {
      if (state.refreshing) {
        state.list = [];
        state.page = 1;
        state.refreshing = false;
      }
      const { data } = await fireService.fireLists(state.page, state.pageSize);

      state.list.push(...data.data);

      state.loading = false;
      state.page++;
      if (state.list.length >= data.total) {
        state.finished = true;
      }
    };

    const onRefresh = () => {
      state.finished = false;
      state.loading = true;
      onLoad();
    };

    return {
      state,
      onLoad,
      onRefresh,
      colors
    };
  }
});
